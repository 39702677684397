import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';
import { registerDestructor } from '@ember/destroyable';
import { getQueryParam } from 'mewe/shared/utils';

import Verbose from 'mewe/utils/verbose';
const verbose = Verbose({ prefix: '[Impression]', color: 'orange', enabled: true }).log;

/**
 * Usage:
 *
 * {{element-tracker-intersection @model (hash threshold=0.2)}}
 *
 * model: the model to track
 * options: the options for the intersection observer
 */

const DEFAULT_OPTIONS = {
  threshold: 0, // when the element is visible
};

export default class ElementTrackerIntersectionModifier extends Modifier {
  @service account;
  @service analytics;
  @service dynamicDialogs;
  @service router;

  observer = null;
  visibleStartTime = null;
  totalVisibleTime = 0;
  visibleRatio = 0;
  impressionStartTime = null;
  impressionEndTime = null;
  tagFilter = null;
  _hasAnyDialogOpened;

  constructor() {
    super(...arguments);
    addObserver(this, 'dynamicDialogs.hasAnyDialogOpened', this.checkDialogs);
    this._hasAnyDialogOpened = this.dynamicDialogs.hasAnyDialogOpened;
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    // This is check if the user is on the page or not
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    // has to be set at initialisation because at the time of sending the
    // the route can be already changed and checking url param will give wrong results
    this.tagFilter = getQueryParam('tag');

    // Register the destructor
    registerDestructor(this, () => {
      this.cleanup();
    });
  }

  // Specific to post model
  extractPostData(model) {
    let post_has_photos = !!model.photo && !model.isVideo;
    let index = model.additionalData?.index;
    let post_type;

    if (model.eventId) {
      post_type = 'event';
    } else if (model.groupId) {
      post_type = 'group';
    } else if (model.pageId) {
      post_type = 'page';
    } else if (model.threadId) {
      post_type = 'private';
    } else if (model.closeFriends) {
      post_type = 'favorites';
    } else if (model.everyone) {
      post_type = 'everyone_on_mewe';
    } else if (model.public) {
      post_type = 'anyone';
    } else {
      post_type = 'followers';
    }

    let groupData = {};

    if (this.context === 'group') {
      groupData = {
        group_category: model?.group?.groupThematicType,
        group_link_id: model?.group?.publicUrlId,
        group_members_count: model?.group?.membersCount,
        group_type:
          model.group?.name === 'Home'
            ? null
            : model?.group?.isPublicApply
            ? 'selective'
            : model?.group?.isPublic
            ? 'open'
            : 'private',
      };
    }

    let postData = {
      post_id: model.id,
      reshare_post_id: model.refPost?.id,
      index: index || index === 0 ? index + 1 : null,
      feed_filter: this.feedFilter,
      post_creator_id: model.owner?.id,
      // post_creator_is_nsfw: !!model?.owner?._links?.avatar?.nsfw, // we are skipping this for now
      post_creator_link_id: model?.owner?.publicLinkId,
      // post_creator_followers_count: model?.owner?.contactsCount, // we are skipping this for now
      post_image_is_nsfw: post_has_photos
        ? model?.medias?.find((media) => media?.photo?.isNsfw)
          ? true
          : false
        : null,
      emojis: model?.emojisItems,
      // post_user_emojied_count: model.emojis?.total, // we are skipping this for now
      post_comments_count: model.comments?.total,
      post_reshare_count: model.sharesCount,
      post_has_files: model.files?.length > 0,
      post_has_gifs: model.gifUrls?.length > 0,
      post_has_photos,
      post_has_stickers: model.stickers?.length > 0,
      post_has_polls: !!model.poll,
      post_has_videos: model.isVideo,
      post_type,
      post_text_length: model.textServer?.length || 0,
      post_preview_link_domain: model?.link?._links?.url?.href,
      hashtags: model.hashTags,
      group_id: model.groupId,
      group_name: model.group?.name === 'Home' ? null : model.group?.name,
      // group_is_banned: model.groupIsBanned, // Group ban is not available
      page_id: model.pageId,
      page_followers_count: model?.page?.followers,
      page_name: model.page?.name,
      page_category: model.page?.category?.name,
      event_id: model.eventId,
      post_video_id: model.video?.id,
      single_post_view_source: model.additionalData?.spvSource,
      // post_video_size: model.video?.size, // Video size is not available, but we can get video length
      ...groupData,
    };

    // Remove properties with undefined values
    Object.keys(postData).forEach(
      (key) => (postData[key] === undefined || postData[key] === null) && delete postData[key]
    );
    return postData;
  }

  get activeUser() {
    return this.account.activeUser;
  }

  get feedFilter() {
    if (this.model.additionalData.feedFilter) {
      return this.model.additionalData.feedFilter;
    } else if (this.context === 'feed_following' && this.router.currentURL.includes('filter=favorites')) {
      return 'favorites';
    } else if (this.tagFilter) {
      return 'tag';
    }
    return null;
  }

  get baseInfo() {
    return {
      user_id: this.activeUser.id,
      user_link_id: this.activeUser.profileId,
      context: this.context,
    };
  }

  get canStopObserver() {
    // We don't want to stop the observer if the dialog is open when post is previewed search or link or group preview
    return !['search', 'link', 'group_preview'].includes(this.context);
  }

  draftTimeFrameData() {
    return {
      impression_start: this.impressionStartTime,
      impression_end: this.impressionEndTime,
      impression_length: this.totalVisibleTime.toFixed(2),
      visible_part_on_end: this.visibleRatio,
    };
  }

  draftImpressionData() {
    return {
      ...this.baseInfo,
      ...this.draftTimeFrameData(),
      ...this.extractPostData(this.model), // Model specifc, we need to extract the data from the model
    };
  }

  observerCallback(entries) {
    let entry = entries[0];
    this.visibleRatio = entry.intersectionRatio;
    if (entry.isIntersecting) {
      // Element is visible
      if (!this.visibleStartTime) {
        this.visibleStartTime = performance.now();
        this.impressionStartTime = new Date().getTime();
      }
    } else {
      // Element is not visible
      if (this.visibleStartTime) {
        this.totalVisibleTime += (performance.now() - this.visibleStartTime) / 1000;
        this.visibleStartTime = null;
      }
      this.sendAnalytics();
    }
  }

  sendAnalytics() {
    this.impressionEndTime = new Date().getTime();
    let impressionData = this.draftImpressionData();

    // Send impression data to analytics if the impression length is greater than 500ms
    if (impressionData.impression_length > 0.5) {
      // Reset the total visible time, second time we send the fresh length
      this.totalVisibleTime = 0;

      this.analytics.sendEvent('postViewed', impressionData);

      verbose(
        'Post ID:' +
          this.model.id +
          ' <<< context: ' +
          impressionData.context +
          ' >>> Length: { ' +
          impressionData.impression_length +
          's }',
        impressionData,
        this.model
      );
    }
  }

  modify(element, [modelObject, options = {}]) {
    if (
      modelObject.additionalData?.isSharedPost ||
      (modelObject.additionalData?.inMediaDialog && modelObject.model?.medias?.length > 1) ||
      modelObject.model?.scheduled ||
      modelObject.additionalData?.isPendingFeed
    ) {
      // Don't track post inside shared post
      return;
    }
    this.element = element;
    this.model = modelObject.model;
    this.context = modelObject.context;
    if (modelObject.additionalData) {
      this.model.additionalData = modelObject.additionalData;
    }

    let opts = { ...DEFAULT_OPTIONS, ...options };

    this.observer = new IntersectionObserver(this.observerCallback.bind(this), opts);
    this.startObserver();
  }

  startObserver() {
    if (this.observer && this.element) {
      this.observer.observe(this.element);
    }
  }

  stopObserver() {
    if (this.observer && this.element) {
      // Calculate final visible time if element is still visible
      if (this.visibleStartTime) {
        this.totalVisibleTime += (performance.now() - this.visibleStartTime) / 1000;
        this.visibleStartTime = null;
        this.sendAnalytics();
      }
      this.observer.unobserve(this.element);
    }
  }

  checkDialogs() {
    if (this.dynamicDialogs.hasAnyDialogOpened && this.canStopObserver) {
      this.stopObserver();
    } else {
      this.startObserver();
    }
  }

  handleVisibilityChange() {
    if (document.hidden) {
      this.stopObserver();
    } else {
      this.startObserver();
    }
  }

  cleanup() {
    this.stopObserver();
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
    removeObserver(this, 'dynamicDialogs.hasAnyDialogOpened', this.checkDialogs);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}
