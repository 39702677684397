import dispatcher from 'mewe/dispatcher';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { Theme } from 'mewe/constants';

const getRegexpHost = () => {
  return EnvironmentUtils.getShareHost()
    .toString()
    .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // escape regexp constants
};

const getPostRegExp = () => {
  return new RegExp(
    '(?:(?:(?:http|https)://){0,1}' +
      getRegexpHost() +
      ')/(?:(?:(post)/show/([0-9a-zA-Z]+))|(?:(event|group|page)/([0-9a-zA-Z]+)/show/([0-9a-zA-Z]+))|(?:([0-9a-zA-Z]+)/show/([0-9a-zA-Z]+)))',
    'ig'
  );
};

export const openSinglePost = (url) => {
  if (!url) {
    return;
  }

  const match = getPostRegExp().exec(url);

  if (!match || match.length === 0) {
    return;
  }

  let myWorldScope = match[1],
    postItemId;

  if (myWorldScope) {
    postItemId = match[2];
    myWorldScope = 'contacts';

    dispatcher.dispatch('feed', 'openSinglePost', {
      scope: Theme.CONTACTS,
      postItemId: postItemId,
      isPostFromUrl: true,
    });
  } else {
    let feedScope = match[3],
      feedId = match[4];

    postItemId = match[5];
    if (feedScope) {
      dispatcher.dispatch('feed', 'openSinglePost', {
        scope: feedScope,
        groupId: feedId,
        eventId: feedId,
        pageId: feedId,
        postItemId: postItemId,
      });
    } else {
      const pagePublicLinkId = match[6];
      const postId = match[7];
      dispatcher.dispatch('feed', 'openSinglePost', {
        scope: 'page-link-id',
        pageId: pagePublicLinkId,
        postItemId: postId,
      });
    }
  }

  return true;
};
