import Promise from 'rsvp';

import { scheduleOnce } from '@ember/runloop';

import Model from 'mewe/utils/store-utils/model/read-only-model.js';
import { attr, belongsTo, hasMany } from 'mewe/utils/store-utils/model/attribute';

import { ds } from 'mewe/stores/ds';

import Poll from './poll-model';
import Event from './event-model';
import Emojis from './emojis-model';
import PageModel from './page-model';
import Comments from './comments-model';
import SimpleGroup from './simple-group-model';
import PostDocument from './post-document-model';

import GroupStore from 'mewe/stores/group-store';
import CurrentUserStore from 'mewe/stores/current-user-store';

import { modelEmojisReady } from 'mewe/stores/models/mixins/model-emojis-ready';
import { modelEmojisList } from 'mewe/stores/models/mixins/model-emojis-list';
import { modelGifUrls } from 'mewe/stores/models/mixins/model-gif-urls';
import { modelOwner } from 'mewe/stores/models/mixins/model-owner';
import { modelText } from 'mewe/stores/models/mixins/model-text';
import { isDefined } from 'mewe/utils/miscellaneous-utils';
import { getPage } from 'mewe/fetchers/fetch-pages';
import PostUtils from 'mewe/utils/post-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import PermissionsUtils from 'mewe/utils/group-permissions-utils';
import { Theme } from 'mewe/constants';

import { serializer as s } from 'mewe/utils/store-utils/serializers/json-serializer';
import { run } from '@ember/runloop';
import EmberObject, { computed, get, set } from '@ember/object';
import { A } from '@ember/array';
import { htmlSafe } from '@ember/template';

var Post = Model.extend(modelEmojisReady, modelEmojisList, modelText, modelGifUrls, modelOwner, {
  textDisplay: computed('textServer', 'emojisReady', function () {
    return this.getTextDisplayWithOptions({
      noStickers: true,
    });
  }),

  id: attr('string', {
    defaultValue: function () {
      return this.get('postItemId');
    },
  }),

  pageId: attr('string'),
  postItemId: attr('string'),
  textServer: attr('string'),
  sharedPostId: attr('string'),
  createdAt: attr('string'),
  updatedAt: attr('string', {
    defaultValue: function () {
      return this.get('createdAt');
    },
  }),
  editedAt: attr('string'),
  scheduled: attr('number'),
  sharesCount: attr('number', { defaultValue: 0 }),
  language: attr('string'),

  refRemoved: attr('boolean', {
    defaultValue: function () {
      return false;
    },
  }),

  public: attr('boolean', {
    defaultValue: function () {
      return false;
    },
  }),

  closeFriends: attr('boolean', {
    defaultValue: function () {
      return false;
    },
  }),

  everyone: attr('boolean', {
    defaultValue: function () {
      return false;
    },
  }),

  sharedTo: computed('{public,closeFriends,everyone}', function () {
    if (this.closeFriends) return 'friends';
    if (this.everyone) return 'everyone';
    if (this.public) return 'public';
    return 'private';
  }),

  postType: attr('string', {
    defaultValue: function () {
      if (this.get('poll')) return 'poll';

      if (this.get('link')) return 'link';

      if (this.get('mediasCount')) {
        if (this.get('mediasCount') === 1) {
          if (this.get('video')) return 'video';

          if (this.get('photo')) return 'photo';
        } else {
          return 'photos';
        }
      }

      if (this.get('files.length')) {
        // if only one file and it's audio then it will be voice message and post is not of a 'documents' type
        if (!this.get('files')[0].audio || this.get('files.length') > 1) {
          return 'documents';
        }
      }

      return 'text';
    },
  }),
  scope: attr('string', {
    // mainly for smart search
    defaultValue: function () {
      if (this.get('pageId')) return 'page';
    },
  }),
  textParts: attr('array'),

  permissions: attr('object', {
    defaultValue: function () {
      return {};
    },
  }),

  user: attr('object'), // <----- comes from WS
  userId: attr('string', {
    defaultValue: function () {
      return this.get('user.id');
    },
  }),
  canEmojify: attr('boolean', {
    defaultValue: function () {
      return this.get('permissions.canEmojify');
    },
  }),

  canAddEmoji: attr('boolean', {
    defaultValue: function () {
      return this.get('permissions.canAddEmoji');
    },
  }),

  canRecommend: attr('boolean', {
    defaultValue: function () {
      return this.get('permissions.canRecommendToDiscover');
    },
  }),
  previewOnlyPost: attr('boolean', {
    defaultValue: function () {
      // non-contact's private post, can be visible in profile feed if that user sent contact request
      // post can be viewed but actions on it like voting/following can't be done, for now this situation is detected by canEmojify flag
      return !this.get('canEmojify');
    },
  }),
  isWS: attr('boolean'),
  pending: attr('boolean'),
  featured: attr('boolean'),
  contacts: attr('boolean', {
    defaultValue: function () {
      return this.get('postScope') && this.get('postScope') === Theme.CONTACTS;
    },
  }),
  privacymail: attr('boolean', {
    defaultValue: function () {
      return !!this.get('threadId');
    },
  }),

  comments: belongsTo(Comments, {
    defaultValue: function () {
      return Comments.create();
    },
  }),

  medias: attr('array', {
    defaultValue: function () {
      return [];
    },
  }),
  mediasCount: attr('number'),
  mediaId: attr('string'), // used in photostream, perfectly we shouldn't use PostModel there for media feed
  photo: attr('object', {
    defaultValue: function () {
      return this.get('medias')?.[0]?.photo;
    },
  }),
  video: attr('object', {
    defaultValue: function () {
      return this.get('medias')?.[0]?.video;
    },
  }),

  // for mediastream
  isVideo: attr('boolean', {
    defaultValue: function () {
      return !!this.get('video');
    },
  }),

  link: attr('object'),
  _links: attr('object'), // needed for old post in media dialog in mycloud SG-18875
  document: belongsTo(PostDocument), //TODO remove usage of post-model for document objects and remove this field

  files: hasMany(PostDocument),
  isMultiFiles: attr('boolean', {
    defaultValue: function () {
      return this.get('files.length') > 1;
    },
  }),
  poll: belongsTo(Poll),

  cannotFollow: attr('boolean', {
    defaultValue: function () {
      if (this.scheduled) return true;
      if (this.pending) return true;
      if (this.groupId) {
        // user cannot follow group public post where he is not a member SG-21614
        let groupFromStore = this.getGroupIfMember();
        return groupFromStore ? !groupFromStore.isConfirmed : true;
      }
      return this.isPagePostAdmin || this.previewOnlyPost;
    },
  }),

  canReschedule: computed('page.isOwnerAdmin', 'permissions.reschedule', 'scheduled', function () {
    const { scope } = PostUtils.getPostScopeAndId(this);
    const isPageAndUserIsOwnerAdmin = scope === 'page' && this.page?.isOwnerAdmin;
    const canReschedule = this.permissions.reschedule;

    return this.scheduled && (isPageAndUserIsOwnerAdmin || canReschedule);
  }),

  hashTags: attr('object'),
  follows: attr('boolean', {
    defaultValue: function () {
      return this.isCurrentUserPostOwner || this.hasPagePostPermissions;
    },
  }),

  album: attr('string'),
  albumPreview: attr('object'),
  imgUrl: attr('string'),

  emojis: belongsTo(Emojis, {
    defaultValue: function () {
      return Emojis.create();
    },
  }),

  eventInfo: belongsTo(Event),
  eventId: attr('string'),
  event2: attr(Event, {
    defaultValue: function () {
      let allEvent = ds.events
        .for('all-events')
        .get('items')
        .find((i) => i.get('id') === this.eventId);

      let upcomingEvent = ds.events
        .for('upcoming-events')
        .get('items')
        .find((i) => i.get('id') === this.eventId);

      return allEvent || upcomingEvent;
    },
  }),

  groupId: attr('string'),

  groupSimple: belongsTo(SimpleGroup, {
    defaultValue: function () {
      return this.getGroup(this.groupId);
    },
  }),

  // this is rewritten into normal full group in some cases
  // SG-28806, SG-29163
  group: belongsTo(SimpleGroup, {
    defaultValue: function () {
      return this.getGroup(this.groupId);
    },
  }),

  getGroup: function (groupId) {
    // we default 'group' field to 'contacts' for non group posts for some unknown legacy reasons
    if (!groupId) {
      return SimpleGroup.create({
        id: Theme.CONTACTS,
        name: 'Home',
      });
    }

    if (groupId) {
      // we want to provide full group data if possible
      let group = this.getGroupIfMember();

      // if full group data is not available then let's try simple group object
      if (!group) {
        group = ds.simpleGroups[groupId];

        // just in case we don't have this group neither in GroupStore (user is not a member of that group)
        // nor in SimpleGroupStore (post didn't arrive in any feed/post response but from WS), then we
        // create dummy group object as a fallback for such edge case
        if (!group) {
          group = EmberObject.create({
            id: groupId,
            name: __('Group'),
          });
        }
      }

      return group;
    }
  },

  // checking if group is stored in confirmedGroups, if yes then group object is returned
  // the goal is to be able to check if user is a member of group from which posts origins
  getGroupIfMember: function () {
    return this.groupId ? GroupStore.getGroupIfMember(this.groupId) : null;
  },

  page: belongsTo(PageModel, {
    defaultValue: function () {
      return this.pageId && getPage(this.pageId);
    },
  }),

  count: attr('number'),
  name: attr('string'),
  needsLoggedIn: attr('boolean'),
  image: attr('object'),
  postId: attr('string'),
  multiPostId: attr('string'),
  threadId: attr('string'),
  no: attr('number'),
  placeholder: attr('boolean', {
    defaultValue: false,
  }),

  initialCommentsSet: attr('boolean', {
    defaultValue: false,
  }), //only for comments button in smart search - no backend relation

  // 'group' or 'page'
  // set on client side for discover feed or comes from BE when discover post in all feed
  discoverScope: attr('string', {
    defaultValue: '',
  }),

  isGroupPreview: attr('boolean', {
    defaultValue: false,
  }),

  // set on client side for open profile feed - not authenticated user
  isOpenProfilePost: attr('boolean', {
    defaultValue: false,
  }),

  // set on client side for open page feed - not authenticated user
  isOpenPagePost: attr('boolean', {
    defaultValue: false,
  }),

  // when true commenting action should not be active
  commentsDisabled: attr('boolean', {
    defaultValue: false,
  }),

  isPublicContent: computed('isOpenProfilePost', 'isOpenPagePost', function () {
    if (this.isOpenProfilePost || this.isOpenPagePost) {
      return true;
    }
    return false;
  }),

  customInteraction: computed('discoverScope', 'isPublicContent', function () {
    if (this.isPublicContent) return true;
    if (this.discoverScope === 'group') return true;

    return false;
  }),

  isContactsPost: computed('contacts', 'groupId', 'eventId', 'pageId', 'threadId', function () {
    if (!this.groupId && !this.eventId && !this.pageId && !this.threadId) return true;

    if (isDefined(this.contacts)) return this.contacts;
  }),

  isGroupPost: computed('group.id', 'threadId', function () {
    const groupId = this.get('group.id');
    return groupId && groupId !== Theme.CONTACTS && !this.threadId;
  }),

  isMycloud: computed('group.id', 'contacts', function () {
    // I think group.id is never 'mycloud' but leaving it for now to don't break anything
    return this.get('group.id') === Theme.MYCLOUD || (this.get('group.id') === Theme.CONTACTS && !this.contacts);
  }),

  canRemove: computed(
    'permissions.remove',
    'hasPagePostPermissions',
    'isCurrentUserPostOwner',
    'isContactsPost',
    'eventId',
    'event2.ownerId',
    'groupId',
    'scheduled',
    function () {
      if (this.scheduled) return false;

      // in some cases permissions.remove is explicitly set to false because it can't be calculated on client side
      if (isDefined(this.get('permissions.remove'))) return this.get('permissions.remove');

      if (this.hasPagePostPermissions) return true;

      if (this.isCurrentUserPostOwner) return true;

      /*
       * owner/ admin of the group can remove posts, but admin cannot remove owner's posts
       **/
      if (this.groupId) {
        const group = this.getGroupIfMember();
        if (group) {
          const isPostOwnerGroupOwner = group.ownerId === this.get('owner.id');

          if (group.isOwnerAdmin && isDefined(isPostOwnerGroupOwner) && !isPostOwnerGroupOwner) {
            return true;
          }
        }
      }

      /*
       * owner of the event can remove posts
       **/
      if (this.eventId && this.get('event2.ownerId') === CurrentUserStore.getState().get('id')) {
        return true;
      }
    }
  ),

  canFeature: computed(
    'closeFriends',
    'permissions.canFeature',
    '{hasPagePostPermissions,scheduled,pending,isCurrentUserPostOwner,isContactsPost,eventId,eventId,groupId,multiPostId}',
    'event2.ownerId',
    function () {
      if (this.closeFriends) return false;

      if (isDefined(this.get('permissions.canFeature'))) return this.get('permissions.canFeature');

      if (this.pending) return false;

      //isScheduled is only for future post - does not apply for scheduled and waiting to reschedule
      if (this.scheduled) return false;
      if (this.multiPostId) return false;

      if (this.hasPagePostPermissions) return true;

      /*
       * owner/ admin of the group can feature posts
       **/
      if (this.groupId) {
        const group = this.getGroupIfMember();
        if (group?.isOwnerAdmin) return true;
      }

      /*
       * owner of the event can feature posts
       **/
      if (this.eventId && this.get('event2.ownerId') === CurrentUserStore.getState().get('id')) {
        return true;
      }

      /**
       * if user is postOwner and post is for contacts, he can feature it
       * additional check regarding feedTheme are done in mw-post in showFeaturePostOption
       */
      if (this.isContactsPost && this.isCurrentUserPostOwner) return true;
    }
  ),

  canComment: computed(
    'permissions.comment',
    'hasPagePostPermissions',
    'discoverScope',
    'isGroupPreview',
    'pending',
    'groupId',
    'pageId',
    'isContactsPost',
    'page.allowFollowersToCommentPost',
    'isPublicContent',
    'isCurrentUserPostOwner',
    'commentsDisabled',
    function () {
      if (this.isPublicContent) return false;
      if (this.commentsDisabled && !this.isCurrentUserPostOwner) return false;
      if (isDefined(this.get('permissions.comment'))) return this.get('permissions.comment');

      if (this.pageId) {
        if (this.page?.isOwnerAdmin) return true;

        if (isDefined(this.get('page.allowFollowersToCommentPost'))) {
          return this.get('page.allowFollowersToCommentPost');
        } else {
          return true;
        }
      }

      if (this.isGroupPreview) return false;
      if (this.discoverScope) return true;

      /**
       * if post is contacts/ main profile post, rely on backend
       * permission completely, we don't know if users
       * are contacts or not - SG-24609
       */
      if (this.isContactsPost) return this.isWS || this.get('permissions.comment');

      if (this.hasPagePostPermissions) return true;

      if (this.pending) return false;

      if (!this.groupId && !this.pageId) return true;

      if (this.groupId) {
        let groupFromStore = GroupStore.getGroupIfExists(this.groupId);
        if (groupFromStore) return PermissionsUtils.canComment(groupFromStore.permissions);
      }
    }
  ),

  canEdit: computed(
    'permissions.edit',
    '{hasPagePostPermissions,isCurrentUserPostOwner,isGroupPost,scheduled}',
    function () {
      if (this.scheduled) return this.permissions.edit;
      if (isDefined(this.get('permissions.edit'))) return this.get('permissions.edit');

      // there is situation when user is owner of the post, but he cannot edit it - group - limited permission after approval SG-21341
      if (this.isCurrentUserPostOwner) {
        if (this.isGroupPost) {
          const group = GroupStore.getState({ id: this.get('group.id') });
          return group && group.get('canPostWithoutModeration');
        } else if (this.eventId) {
          return this.get('permissions.edit');
        }

        return true;
      }

      return this.hasPagePostPermissions;
    }
  ),

  hasPagePostPermissions: computed('postedByPage', 'page.isOwnerAdmin', function () {
    return this.postedByPage && this.get('page.isOwnerAdmin');
  }),

  canOpenAlbum: computed('{pending,scheduled,groupId,discoverScope,isGroupPreview}', function () {
    if (this.pending || this.scheduled || this.discoverScope || this.isGroupPreview) return false;

    if (this.groupId) {
      const groupFromStore = this.getGroupIfMember();
      return groupFromStore?.isConfirmed; // user cannot open group album where he is not a member SG-22812
    }

    return true;
  }),

  isCurrentUserPostOwner: computed('owner.id', 'postedByPage', function () {
    let currentUser = CurrentUserStore.getState();
    if (!currentUser) return false;
    return this.get('owner.id') === currentUser.get('id') && !this.postedByPage;
  }),

  canAddToAlbum: computed(
    'isCurrentUserPostOwner',
    'isPagePostAdmin',
    'postScope',
    'photo',
    'video',
    'closeFriends',
    'scheduled',
    function () {
      if (this.closeFriends || this.scheduled) return false;

      if (this.postScope === Theme.MYCLOUD || this.pending) return false;

      // albums in private posts are not specified - SG-15520, SG-19186
      if (this.threadId) return false;

      const isProperPostType = this.photo || this.video;

      return isProperPostType && (this.isCurrentUserPostOwner || this.isPagePostAdmin);
    }
  ),

  canDownload: computed('canShare', 'postType', 'public', function () {
    return (this.public || this.canShare) && this.postType === 'photo';
  }),

  canReport: computed('owner.id', 'page.isOwnerAdmin', function () {
    return !this.isCurrentUserPostOwner && !this.get('page.isOwnerAdmin');
  }),

  canCopyLink: computed('{threadId,pending,scheduled}', function () {
    return !this.threadId && !this.pending && !this.scheduled;
  }),

  bookmarkId: attr('string'), // local only, saved after bookmarking
  canBookmark: computed('{pending,scheduled,sharedTo,contacts,threadId}', function () {
    const isPrivateContactsPost = this.sharedTo === 'private' && this.contacts;
    return !this.pending && !this.scheduled && !this.threadId && !isPrivateContactsPost;
  }),

  canRemoveAndBlock: computed('isCurrentUserPostOwner', 'canRemove', 'postedByPage', function () {
    return this.canRemove && !this.isCurrentUserPostOwner && !this.postedByPage;
  }),

  previewImages: computed('medias', 'mediasCount', 'morePhotos', 'isPublicContent', function () {
    const mediasCount = this.get('mediasCount');
    let photos = this.get('medias');

    // TODO rework this function in some predictable way
    photos.forEach((image, i) => {
      if (!image.photo && image.video) {
        set(image, 'imageIndex', i);
        set(image, 'backgroundImage', htmlSafe(`background-color: hsl(0, 0%, 0%););`));
      }

      if (!image.photo) return;

      let imageUrl;

      if (mediasCount === 2) {
        imageUrl =
          EnvironmentUtils.getImgHost(true, this.isPublicContent) +
          image.photo._links.img.href.replace('{imageSize}', '400x400');
      } else if (mediasCount > 2) {
        if (i === 0) {
          imageUrl =
            EnvironmentUtils.getImgHost(true, this.isPublicContent) +
            image.photo._links.img.href.replace('{imageSize}', '800x800');
        } else {
          imageUrl =
            EnvironmentUtils.getImgHost(true, this.isPublicContent) +
            image.photo._links.img.href.replace('{imageSize}', '400x400');
        }
      } else {
        imageUrl =
          EnvironmentUtils.getImgHost(true, this.isPublicContent) +
          image.photo._links.img.href.replace('{imageSize}', '400x400');
      }

      imageUrl = imageUrl.replace('{static}', '1');

      set(image, 'imageUrl', imageUrl);
      set(image, 'imageIndex', i);
      set(image, 'backgroundImage', htmlSafe(`background-image: url(${imageUrl});`));
    });

    return photos;
  }),

  morePhotosText: computed('morePhotos', function () {
    return this.get('morePhotos')
      ? __('... and {count} more', {
          count: this.get('morePhotos'),
        })
      : '';
  }),

  morePhotos: computed('mediasCount', function () {
    const maxVisiblePhotosCount = 4;
    return this.get('mediasCount') > maxVisiblePhotosCount ? this.get('mediasCount') - maxVisiblePhotosCount + 1 : '';
  }),

  showDropdownMenu: computed('canEdit', 'canFeature', 'canRemove', 'canAddToAlbum', 'cannotFollow', function () {
    if (this.get('canEdit')) return true;
    if (this.get('canFeature')) return true;
    if (this.get('canRemove')) return true;
    if (this.get('canAddToAlbum')) return true;
    if (!this.get('cannotFollow')) return true;
  }),

  postRendered() {
    return new Promise((resolve) => {
      scheduleOnce('afterRender', this, () => {
        resolve();
      });
    });
  },

  textPartsHighlighted: computed('textParts', function () {
    return this.get('textParts') ? A(this.get('textParts')).filterBy('isHighlighted') : null;
  }),

  firstPostPerformance: attr('boolean'),
  postedByPage: attr('boolean'),

  isPagePostAdmin: computed('postedByPage', 'page.isOwnerAdmin', function () {
    return this.get('postedByPage') && this.get('page.isOwnerAdmin');
  }),

  isCurrentUserPagePostOwner: computed('userId', function () {
    let currentUser = CurrentUserStore.getState();
    if (!currentUser) return false;
    return this.get('userId') === currentUser.get('id') && this.postedByPage;
  }),

  canShare: computed(
    'refPost',
    'sharedTo',
    'page',
    'refPost.refRemoved',
    'refRemoved',
    'multiPostId',
    'permissions.reshare',
    'isContactsPost',
    'discoverScope',
    'isGroupPreview',
    'group.{isPublic,isPublicApply,discoveryOptIn}',
    function () {
      // ORDER is important here:
      // 1. if BE provides already calculated permission, we trust them
      if (this.permissions.reshare) return true;
      if (this.permissions.repost) return false;
      // 2. posts about group event can't be shared
      if (this.eventInfo && this.group.id !== Theme.CONTACTS) return false;
      // 3. posts inside group event can't be shared
      if (this.event2?.groupId) return false;
      // 4. can't share single media from multi-media post in any case if BE permission doesn't say opposite
      if (this.multiPostId) return false;
      // 5. Discover posts are from public groups and can always be shared
      if (this.discoverScope) return true;
      // 6. Group Preview posts are from public group and always be shared unless rejected in logic about (event posts)
      if (this.isGroupPreview) return true;

      if (this.group) {
        // only group that user is member of is relevant, otherwise we won't have detailed info about that group
        const group = this.getGroupIfMember(); // null if not member

        if (group) {
          // Full group data not fetched yet and without that can't do further calculations
          if (group.isFetching) {
            return false;
          }

          let postInPublicGroup = group.isPublic && !group.isPublicApply;
          let postInSelectiveGroupWithPermission =
            group.isPublic &&
            group.isPublicApply && // checking if selective group
            (group.discoveryOptIn || group.canReshare); // posts from group participating in Discover can be shared, otherwise canReshare flag is the answer

          return postInPublicGroup || postInSelectiveGroupWithPermission;
        }
      }

      /*
       * cannot share from unpublished page SG-21536 - has to be like that,
       * only when it is false, we cannot do this action when undefined
       */
      if (this.page) {
        if (this.get('page.published') === false) return false;
      }

      const sharedTo = this.sharedTo;
      const postIsPublic = sharedTo === 'public' || sharedTo === 'everyone';
      const postIsFriends = sharedTo === 'friends';
      const thereIsRefPost = this.refPost;
      const postIsInPage = this.page;

      return (
        (thereIsRefPost || postIsPublic || postIsInPage) &&
        !postIsFriends &&
        !this.get('refPost.refRemoved') &&
        !this.refRemoved
      );
    }
  ),

  canRepost: computed(
    'canShare',
    'refPost.refRemoved',
    'isCurrentUserPostOwner',
    'refRemoved',
    'isCurrentUserPagePostOwner',
    function () {
      return (
        !this.canShare &&
        !this.get('refPost.refRemoved') &&
        !this.refRemoved &&
        !this.poll &&
        !this.eventInfo &&
        (this.isCurrentUserPostOwner || this.isCurrentUserPagePostOwner)
      );
    }
  ),

  isScheduled: computed('scheduled', function () {
    return new Date(this.scheduled * 1000) > new Date().getTime();
  }),

  stickers: attr('object'),

  sticker: computed('stickers', function () {
    return this.get('stickers.length') ? this.stickers[0] : null;
  }),
});

Post.reopen({
  refPost: belongsTo(Post),
});

Post.reopenClass({
  resourceName: 'post',
  aliases: {
    text: 'textServer',
  },
  refPost: belongsTo(Post),
});

export { Post };

// dataPost needs to be a model object
export const updatePostFromEdit = (post, dataPost) => {
  if (!post || !dataPost) return;

  const fields = get(post.constructor, 'fields');

  let field, value;

  run(() => {
    for (field in fields) {
      if (fields.hasOwnProperty(field)) {
        value = dataPost.get(field);
        post.set(field, value);
      }
    }
  });
};

export const toPost = (post) => s.deserializeOne(Post, post);
